import styled from "styled-components";

export const StyledInputNumber = styled.input`
  color: ${(props) => props.theme.colors.primary};
  border: none;
  font-family: Poppins;
  font-size: 45px;
  font-weight: 500;
  line-height: 1.51;
  width: 100px;
  text-align: center;
  overflow: hidden;
  -moz-appearance: textfield;

  &::placeholder {
    color: ${(props) => props.theme.colors.gray[2]};
    opacity: 1;
  }
`;

export const StyledContainer = styled.div`
  border-bottom: 5px dashed ${(props) => props.theme.colors.gray[2]};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;

  &:focus {
    outline: none;
    border-bottom: 5px dashed
      ${(props) => props.theme.colors.primarySaturation[0]};
  }
`;
