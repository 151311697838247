import React from "react";
import { StyledSavingSuggestion } from "./style";
import { Props } from "./types";

const SavingSuggestion = ({ value, title, description }: Props) => {
  return (
    <StyledSavingSuggestion>
      <div>
        <div className="title">{title}</div>
        <div className="description">{description}</div>
      </div>
      <div className="value">
        <div className="money">$</div>
        <div>{value}</div>
      </div>
    </StyledSavingSuggestion>
  );
};

export default SavingSuggestion;
