const theme = {
    colors: {
        primary: "#7E57C2",
        primaryActive: "#674b9f",
        secondary: "#B2EBF2",
        primarySaturation: ["#CFB3FF", "#F0E6FF"],
        white: "#FFFFFF",
        gray: ["#FAFAFA", "#ECEFF1", "#CFD8DC", "#CCCCCC", "#999999", "#757575", "#212121"],
        green: "#33FF77",
        yellow: "#FFBB33",
        red: "#FF3333",
        pink: "#FF33BB",
        blue: "#3392FF",
        purple: "#7733FF"
    }
}

export default theme;