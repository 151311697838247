import React, { useState, useEffect, useCallback } from "react";
import "./style.css";
import Page from "../../components/Page";
import Button from "../../components/Button";
import { Question } from "../../models/Question";
import Title from "../../components/Title";
import { useHistory } from "react-router-dom";
import CountQuestions from "../../components/CountQuestions";
import ButtonIcon from "../../components/ButtonIcon";
import ListItem from "../../components/ListItem";
import { AllImages } from "../../assets/emojis";
import { useSelector, useDispatch } from "react-redux";
import {
  getQuestionnaire,
  getQuestion,
  requestQuestion,
  questionSuccess,
  updateExpenseQuestionnaireAnswers,
  isLoadingQuestionnaire,
} from "../../store/questionnaire";
import { getUser } from "../../store/session";
import api from "../../service/api";
import { Option } from "../../models/Option";
import InputMoney from "../../components/InputMoney";
import { QuestionnaireAnswer } from "../../models/QuestionnaireAnswer";
import LineGradient from "../../components/LineGradient";
import { useTranslation } from "react-i18next";
import { getIcons } from "../../assets/icons";
import theme from "../../themes/main";
import Spinner from "../../components/Spinner";

const SetExpenses = () => {
  const { t } = useTranslation(["setExpenses"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const questionnaire = useSelector(getQuestionnaire);
  const isLoading = useSelector(isLoadingQuestionnaire);
  const [lastQuestions, setLastQuestions] = useState<Question[]>([]);
  const [value, setValue] = useState<number>();
  const user = useSelector(getUser);
  const question = useSelector(getQuestion);
  const [total, setTotal] = useState(0);

  const onLoad = useCallback(async () => {
    dispatch(requestQuestion());
    await api.delete<{ question: Question }>("questionnaireAnswers", {
      params: {
        userId: user?.id,
        questionnaireId: questionnaire?.id,
      },
    });

    if (questionnaire) dispatch(questionSuccess(questionnaire.FirstQuestion));
  }, [dispatch, user, questionnaire]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const submitQuestion = async (option: Option) => {
    if (question) setLastQuestions([...lastQuestions, question]);
    dispatch(requestQuestion());
    setTotal(total + (value || 0));

    const response = await api.put<{ nextQuestion: Question }>(
      "questionAnswers",
      {
        optionId: option.id,
        userId: user?.id,
        currency: value,
      }
    );

    setValue(undefined);
    if (response.data.nextQuestion)
      dispatch(questionSuccess(response.data.nextQuestion));
    else {
      const responseAnswer = await api.get<{
        questionnaireAnswers: QuestionnaireAnswer[];
      }>("questionnaireAnswers", {
        params: {
          userId: user?.id,
          questionnaireId: questionnaire?.id,
        },
      });
      dispatch(
        updateExpenseQuestionnaireAnswers({
          questionnaireId: questionnaire?.id || 0,
          questionnaireAnswers: responseAnswer.data.questionnaireAnswers,
        })
      );
      history.push("list-categories");
    }
  };

  const returnQuestion = () => {
    history.goBack();
  };

  const backQuestion = () => {
    const last = lastQuestions.pop();
    if (last) dispatch(questionSuccess(last));
  };

  return (
    <Page
      headerContent={
        questionnaire && (
          <div className="header-set-expenses">
            <div className="returnButtons">
              <ButtonIcon icon="arrow" rotate={90} onClick={returnQuestion} />
              {lastQuestions.length > 0 && (
                <button
                  className="returnButtonSecondary"
                  onClick={backQuestion}
                >
                  <img src={getIcons("return-gray")} alt={t("return")} />
                  {t("return")}
                </button>
              )}
            </div>
            <ListItem
              my={24}
              iconColor="#ffe6e6"
              icon={AllImages[questionnaire.emoji] || ""}
              sideContent={
                <div className="value">
                  <span>$</span> {total}
                </div>
              }
            >
              <div>{questionnaire.name}</div>
              <div className="description">{t("totalSpent")}</div>
            </ListItem>
            {!isLoading && (
              <div>
                <CountQuestions
                  color="primary"
                  value={t("counter", {
                    current: lastQuestions.length + 1,
                    total:
                      lastQuestions.length > questionnaire.questionsCount
                        ? lastQuestions.length + 1
                        : questionnaire.questionsCount,
                  })}
                />
                <Title fontSize={32} value={question?.body} color="gray.6" />
              </div>
            )}
          </div>
        )
      }
      footerContent={
        <>
          {!isLoading && (
            <Button
              mb={24}
              value={t("next")}
              icon="arrow-right"
              className="float-button"
              disabled={value === undefined}
              onClick={() =>
                question?.Options && submitQuestion(question?.Options[0])
              }
            />
          )}
        </>
      }
    >
      {isLoading ? (
        <div className="loadingQuestionnaire">
          <Spinner size={32} color={theme.colors.primary} top={200} left={30} />
        </div>
      ) : (
        <InputMoney onChangeValue={setValue} value={value} />
      )}
      <LineGradient />
    </Page>
  );
};

export default SetExpenses;
