import React from "react";
import { StyledBottomModal } from "./style";
import { Props } from "./types";

const BottomModal = (props: Props) => {
  const { title, description, children } = props;

  return (
    <StyledBottomModal {...props}>
      {title && <span className="title">{title}</span>}
      {description && <span className="description">{description}</span>}
      <div className="content">{children}</div>
    </StyledBottomModal>
  );
};

BottomModal.defaultProps = {
  marginTop: 0,
};

export default BottomModal;
