import React from "react";
import { StyledInputText } from "./style";
import { Props } from "./types";
import { useTranslation } from "react-i18next";

const InputText = (props: Props) => {
  const { t } = useTranslation(["inputText"]);
  const { value, onChangeValue, ...otherProps } = props;
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChangeValue(e.target.value);
  };

  return (
    <StyledInputText
      placeholder={t("typeHere")}
      {...otherProps}
      onChange={onChange}
      value={value}
    />
  );
};

export default InputText;
