import React, { useState } from "react";
import "./style.css";
import Page from "../../components/Page";
import Header from "../../components/Header";
import ColumnSelector from "../../components/ColumnSelector";
import ProgressBar from "../../components/ProgressBar";
import { Question } from "../../models/Question";
import Title from "../../components/Title";
import { useHistory } from "react-router-dom";
import CountQuestions from "../../components/CountQuestions";
import { useSelector, useDispatch } from "react-redux";
import {
  getQuestion,
  isLoadingQuestionnaire,
  requestQuestion,
  questionSuccess,
  getFirstQuestionnaire,
} from "../../store/questionnaire";
import { getUser } from "../../store/session";
import { Option } from "../../models/Option";
import api from "../../service/api";
import Spinner from "../../components/Spinner";
import { useTranslation } from "react-i18next";
import { getIcons } from "../../assets/icons";

const FirstQuestionnaire = () => {
  const { t } = useTranslation(["firstQuestionnaire"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [lastQuestions, setLastQuestions] = useState<Question[]>([]);
  const user = useSelector(getUser);
  const question = useSelector(getQuestion);
  const isLoadingQuestion = useSelector(isLoadingQuestionnaire);
  const questionnaire = useSelector(getFirstQuestionnaire);

  const submitQuestion = async (option: Option) => {
    if (question) setLastQuestions([...lastQuestions, question]);
    dispatch(requestQuestion());
    const response = await api.put<{ nextQuestion: Question; nudge: string }>(
      "questionAnswers",
      {
        optionId: option.id,
        userId: user?.id,
      }
    );

    if (response.data.nextQuestion)
      dispatch(questionSuccess(response.data.nextQuestion));
    else
      history.push("nudge", {
        nudge: response.data.nudge || questionnaire?.nudge,
      });
  };

  const backQuestion = () => {
    const last = lastQuestions.pop();
    if (last) dispatch(questionSuccess(last));
  };

  return (
    <Page
      backgroundGradient
      headerContent={
        <div className="headerFirstQuestionnaire">
          <Header
            title={user?.name || ""}
            description={`Financial Discovery`}
            sideContent={
              lastQuestions.length > 0 && (
                <button className="returnButton" onClick={backQuestion}>
                  <img src={getIcons("return")} alt={t("return")} />
                  {t("return")}
                </button>
              )
            }
          />
          <ProgressBar
            current={lastQuestions.length + 1}
            total={questionnaire?.questionsCount || 0}
            mt={2}
          />
        </div>
      }
    >
      {isLoadingQuestion ? (
        <div className="loadingFirstQuestionnaire">
          <Spinner size={32} />
        </div>
      ) : (
        <>
          <CountQuestions
            color="primary"
            value={t("counter", {
              current: lastQuestions.length + 1,
              total: questionnaire?.questionsCount || lastQuestions.length + 1,
            })}
          />
          <Title value={question?.body} />
          <ColumnSelector
            labelYes={question?.Options[0].body}
            labelNo={question?.Options[1].body}
            handlerYes={() => question && submitQuestion(question.Options[0])}
            handlerNo={() => question && submitQuestion(question.Options[1])}
          />
        </>
      )}
    </Page>
  );
};

export default FirstQuestionnaire;
