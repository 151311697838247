import React from "react";
import { StyledTitle } from "./style";
import { Props } from "./types";

const Title = (props: Props) => {
  const { children, value } = props;

  return (
    <StyledTitle {...props}>
      {value}
      {children}
    </StyledTitle>
  );
};

export default Title;
