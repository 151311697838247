import React from "react";
import { StyledListItem } from "./style";
import { Props } from "./types";

const ListItem = (props: Props) => {
  const { children, icon, sideContent } = props;

  return (
    <StyledListItem {...props}>
      {icon && (
        <div className="icon">
          <img src={icon} alt="Icon" />
        </div>
      )}
      <div className="content">{children}</div>
      {sideContent}
    </StyledListItem>
  );
};

export default ListItem;
