import styled from "styled-components";

const LineGradient = styled.div`
  height: 8px;
  width: 100vw;
  background-image: linear-gradient(91deg, #b2ebf2, #7e57c2);
  position: fixed;
  bottom: 0;
  left: 0;
`;

export default LineGradient;
