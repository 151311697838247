import styled from "styled-components";
import { Props } from "./types";
import { space } from "styled-system";

export const StyledSavingSuggestion = styled.div<Props>`
  ${space}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .title {
    line-height: 1.6;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
  }
  .description {
    font-weight: normal;
    line-height: 1.33;
    opacity: 0.8;
    font-family: Poppins;
    font-size: 12px;
    color: ${(props) => props.theme.colors.gray[1]};
  }

  .value {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    line-height: 1.5;
    font-family: Poppins;
    font-size: 32px;
    font-weight: 500;
  }

  .money {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 500;
    line-height: 1.5;
    color: #a1e6b8;
  }
`;
