import styled from "styled-components";
import { space } from "styled-system";
import { Props } from "./types";
import { ThemeProps } from "../../themes/types";

const getActiveLayout = ({ selected, theme }: Props & { theme: ThemeProps }) =>
  selected &&
  ` color: ${theme.colors.primary};
  border: solid 1px ${theme.colors.primary};
  background-color: ${theme.colors.primarySaturation[1]};`;

const getDisabledLayout = ({
  disabled,
  theme,
}: Props & { theme: ThemeProps }) =>
  disabled &&
  `background: ${theme.colors.gray[1]};
   color: ${theme.colors.gray[3]};`;

export const StyledRadioButton = styled.button<Props>`
  ${space};
  width: 100%;
  height: 64px;
  display: flex;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
  border-radius: 8px;
  padding: 16px 24px;
  align-items: center;
  font-family: Poppins;
  transition: all 300ms;
  justify-content: flex-start;
  color: ${(props) => props.theme.colors.gray[5]};
  background-color: ${(props) => props.theme.colors.white};
  border: solid 1px ${(props) => props.theme.colors.gray[2]};
  & img {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    border-radius: 16px;
  }
  &:hover {
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.primarySaturation[1]};
  }
  &:active {
    color: ${(props) => props.theme.colors.primary};
    border: solid 1px ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.primarySaturation[1]};
  }
  ${(props) => getActiveLayout(props)}
  ${(props) => getDisabledLayout(props)}
`;
