import React from "react";
import { StyledProgressBar } from "./style";
import { Props } from "./types";

const ProgressBar = (props: Props) => {
  return <StyledProgressBar {...props}>
    <span className="progress" />
  </StyledProgressBar>;
};

export default ProgressBar;
