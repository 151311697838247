import ArrowRight from "./arrow-right.svg";
import Arrow from "./arrow.svg";
import Dropdown from "./dropdown.svg";
import DisabledArrowRight from "./disabled-arrow-right.svg";
import Pencil from "./pencil.svg";
import Edit from "./edit.svg";
import Less from "./less.png";
import Plus from "./plus.png";
import Exit from "./exit.svg";
import ExitGray from "./exit-gray.svg";
import Return from "./return.svg";
import ReturnGray from "./return-gray.svg";

export const getIcons = (icon: string) => {
  switch (icon) {
    case "arrow-right":
      return ArrowRight;
    case "arrow":
      return Arrow;
    case "pencil":
      return Pencil;
    case "edit":
      return Edit;
    case "less":
      return Less;
    case "plus":
      return Plus;
    case "exit":
      return Exit;
    case "exit-gray":
      return ExitGray;
    case "return":
      return Return;
    case "return-gray":
      return ReturnGray;

    default:
      return "";
  }
};

export const getDisabledIcons = (icon: string) => {
  switch (icon) {
    case "arrow-right":
      return DisabledArrowRight;

    default:
      return "";
  }
};

export const DropdownSvg = Dropdown;
