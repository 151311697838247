import styled from "styled-components";
import { Props } from "./types";

export const StyledBottomModal = styled.div<Props>`
  width: calc(100vw - 48px);
  height: calc(100vh - ${(props) => (props.marginTop || 0) + 64}px);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${(props) => props.marginTop}px;
  left: 0;
  padding: 32px 24px;
  box-shadow: 0 -4px 20px 0 rgba(126, 87, 194, 0.12);
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  background: ${(props) => props.theme.colors.white};
  overflow: scroll;

  .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.6;
    margin-bottom: 12px;
    font-family: Poppins;
    color: ${(props) => props.theme.colors.gray[6]};
  }

  .description {
    font-size: 16px;
    line-height: 1.75;
    font-family: Roboto;
    color: ${(props) => props.theme.colors.gray[5]};
    margin-bottom: 12px;
  }

  .content {
    flex: 1;
  }
`;
