import styled from "styled-components";
import { Props } from "./types";
import { space, position, layout } from "styled-system";

export const StyledSpinner = styled.div<Props>`
  top: 32px;
  right: 24px;
  ${space}
  ${position}
  ${layout}
  border: 
    ${(props) => (props.size || 80) * 0.1}px solid ${(props) =>
    props.color || "rgba(255, 255, 255, 0.4)"};
  border-top: ${(props) => (props.size || 80) * 0.1}px solid
    ${(props) => props.theme.colors.white};
  border-radius: 50%;
  width: ${(props) => props.size || 60}px;
  height: ${(props) => props.size || 60}px;
  animation: spin 1s linear infinite;
  position: absolute;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
