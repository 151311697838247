import axios from "axios";
import env from "react-dotenv";

export default axios.create({
  baseURL: env.URL_API,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
  auth: {
    username: "lmd",
    password: "b8c7nv",
  },
});
