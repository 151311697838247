import React from "react";
import { StyledInputMoney } from "./style";
import { Props } from "./types";
import { useTranslation } from "react-i18next";

const InputMoney = ({ value, onChangeValue }: Props) => {
  const { t } = useTranslation(["inputText"]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChangeValue(parseInt(e.target.value.replace(/\D/g, ""), 10) || 0);
  };

  return (
    <StyledInputMoney
      onChange={onChange}
      placeholder={t("typeHere")}
      value={`$${value === undefined ? `--` : value}`}
    />
  );
};

export default InputMoney;
