import styled from "styled-components";
import { space } from "styled-system";
import { ThemeProps } from "../../themes/types";
import { Props } from "./types";

export const StyledLanguageItem = styled.div<Props>`
  ${space};
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 300ms;
  padding: 8px 16px;
  margin: auto;
  cursor: pointer;
  background: ${(props: Props & { theme: ThemeProps }) =>
    props.disabled ? props.theme.colors.gray[1] : props.theme.colors.white};
  ${(props: Props & { theme: ThemeProps }) =>
    props.active && `background: ${props.theme.colors.primarySaturation[1]}`};

  & .brand {
    height: 32px;
    width: 32px;
    cursor: pointer;
    ${(props: Props & { theme: ThemeProps }) =>
      props.disabled ? "opacity: 0.6;" : "opacity: 1;"};
    background-image: url(${(props) => props.image});
    background-size: 64px;
    border-radius: 16px;
    background-position: center center;
    background-repeat: no-repeat;
  }
  & label {
    cursor: pointer;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: ${(props: Props & { theme: ThemeProps }) =>
      props.theme.colors.gray[props.active ? 6 : 5]};
    ${(props: Props & { theme: ThemeProps }) =>
      props.disabled ? "opacity: 0.8;" : "opacity: 1;"};
  }

  &:hover {
    background: ${(props: { theme: ThemeProps }) =>
      props.theme.colors.primarySaturation[1]};
  }
  &:active {
    background: ${(props: { theme: ThemeProps }) =>
      props.theme.colors.primarySaturation[0]};
  }
`;
