import styled from "styled-components";
import { ThemeProps } from "../../themes/types";
import { Props } from "./types";
import { space } from "styled-system";

export const StyledPage = styled.div<Props>`
  ${space}
  padding: 32px 24px;
  position: relative;
  width: calc(100vw - 48px);
  height: calc(100vh - 64px);
  font-family: Poppins;
  font-size: 24px;
  background: ${(props: Props & { theme: ThemeProps }) =>
    props.theme.colors.white};
  color: ${(props: Props & { theme: ThemeProps }) =>
    props.backgroundGradient
      ? props.theme.colors.white
      : props.theme.colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${(props: Props & { theme: ThemeProps }) =>
    props.backgroundGradient &&
    `background-image: linear-gradient(to bottom, ${props.theme.colors.primary}, ${props.theme.colors.secondary});`};
`;

export const StyledFragment = styled.div<Props>`
  display: block;
  width: 100%;

  &.footer {
    z-index: 9;
  }
`;
