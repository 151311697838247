import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";
import { QuestionnaireState } from "./types";
import { Question } from "../../models/Question";
import { Questionnaire } from "../../models/Questionnaire";
import { QuestionnaireAnswer } from "../../models/QuestionnaireAnswer";

/** Initial state */
export const INITIAL_STATE: QuestionnaireState = {
  question: undefined,
  questionnaire: undefined,
  firstQuestionnaires: undefined,
  expensesQuestionnaires: [],
  incomesQuestionnaire: undefined,
  personalDetailsQuestionnaire: undefined,
  isLoading: false,
  hasGoals: false,
};

/** Action creators */
export const requestQuestion = createAction("REQUEST_QUESTION");
export const requestQuestionnaires = createAction("REQUEST_QUESTIONNAIRES");
export const selectQuestionnaire = createAction<Questionnaire>(
  "SELECT_QUESTIONNAIRE"
);
export const updateHasGoals = createAction<{ hasGoals: boolean }>(
  "UPDATE_HAS_QUESTION"
);
export const questionSuccess = createAction<Question>("QUESTION_SUCCESS");
export const questionnairesSuccess = createAction<{
  firstQuestionnaires?: Questionnaire;
  expensesQuestionnaires?: Questionnaire[];
  incomesQuestionnaire?: Questionnaire;
  personalDetailsQuestionnaire?: Questionnaire;
}>("QUESTIONNAIRES_SUCCESS");
export const questionError = createAction("QUESTION_ERROR");
export const questionnairesError = createAction("QUESTIONNAIRES_ERROR");
export const updateExpenseQuestionnaireAnswers = createAction<{
  questionnaireId: number;
  questionnaireAnswers: QuestionnaireAnswer[];
}>("UPDATE_EXPENSE_QUESTIONNAIRE_ANSWERS");

export const updateIncomeQuestionnaireAnswers = createAction<
  QuestionnaireAnswer[]
>("UPDATE_INCOME_QUESTIONNAIRE_ANSWERS");

/** Reducer */
export default createReducer(INITIAL_STATE, {
  [requestQuestion.type]: (state): QuestionnaireState => ({
    ...state,
    question: undefined,
    isLoading: true,
  }),
  [requestQuestionnaires.type]: (state): QuestionnaireState => ({
    ...state,
    expensesQuestionnaires: [],
    isLoading: true,
  }),
  [updateHasGoals.type]: (state, action): QuestionnaireState => ({
    ...state,
    ...action.payload,
  }),
  [questionSuccess.type]: (state, action): QuestionnaireState => ({
    ...state,
    question: action.payload,
    isLoading: false,
  }),
  [selectQuestionnaire.type]: (state, action): QuestionnaireState => ({
    ...state,
    questionnaire: action.payload,
  }),
  [questionnairesSuccess.type]: (state, action): QuestionnaireState => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),
  [questionError.type]: (state): QuestionnaireState => ({
    ...state,
    isLoading: false,
  }),
  [questionnairesError.type]: (state): QuestionnaireState => ({
    ...state,
    isLoading: false,
  }),
  [updateExpenseQuestionnaireAnswers.type]: (
    state,
    action
  ): QuestionnaireState => ({
    ...state,
    isLoading: false,
    expensesQuestionnaires: state.expensesQuestionnaires.map((questionnaire) =>
      questionnaire.id === action.payload.questionnaireId
        ? {
            ...questionnaire,
            questionAnswers: action.payload.questionnaireAnswers,
          }
        : questionnaire
    ),
  }),
  [updateIncomeQuestionnaireAnswers.type]: (
    state,
    action
  ): QuestionnaireState => ({
    ...state,
    isLoading: false,
    incomesQuestionnaire: state.incomesQuestionnaire && {
      ...state.incomesQuestionnaire,
      questionAnswers: action.payload,
    },
  }),
});

/** Selectors */
const rootSelector = (state: any): QuestionnaireState => state.questionnaire;

export const getQuestion = createSelector(
  [rootSelector],
  (state) => state.question
);

export const getQuestionnaire = createSelector(
  [rootSelector],
  (state) => state.questionnaire
);

export const getExpensesQuestionnaires = createSelector(
  [rootSelector],
  (state) => state.expensesQuestionnaires
);

export const getTotalExpenses = createSelector([rootSelector], (state) =>
  state.expensesQuestionnaires.reduce(
    (fullValue, questionnaire) =>
      fullValue +
      (questionnaire.questionAnswers?.reduce(
        (total, questionAnswer) => total + (questionAnswer.currency || 0),
        0
      ) || 0),
    0
  )
);

export const getIncomeQuestionnaire = createSelector(
  [rootSelector],
  (state) => state.incomesQuestionnaire
);

export const getTotalIncomes = createSelector(
  [rootSelector],
  (state) =>
    state.incomesQuestionnaire?.questionAnswers?.reduce(
      (total, questionAnswer) => total + (questionAnswer.currency || 0),
      0
    ) || 0
);

export const getIncomeValues = createSelector([rootSelector], (state) =>
  state.incomesQuestionnaire?.questionAnswers?.reduce(
    (incomes: QuestionnaireAnswer[], questionAnswer): QuestionnaireAnswer[] => {
      if (!questionAnswer.currency && !questionAnswer.body) return incomes;
      const indexIncome = incomes.findIndex(
        (income) => income.setId === questionAnswer.setId
      );
      if (indexIncome >= 0)
        return incomes.map((income, index) =>
          index === indexIncome
            ? {
                ...income,
                body: questionAnswer.body || income.body,
                currency: questionAnswer.currency || income.currency,
              }
            : income
        );
      return [...incomes, questionAnswer];
    },
    []
  )
);

export const getHighestCategory = createSelector([rootSelector], (state) =>
  state.expensesQuestionnaires.reduce(
    (highestCategory, questionnaire) => {
      const value =
        questionnaire.questionAnswers?.reduce(
          (total, questionAnswer) => total + (questionAnswer.currency || 0),
          0
        ) || 0;

      if (value > highestCategory.value)
        return {
          name: questionnaire.name,
          emoji: questionnaire.emoji,
          nudge: questionnaire.nudge,
          value,
        };
      return highestCategory;
    },
    { name: "", value: 0, emoji: "", nudge: "" }
  )
);

export const getFirstQuestionnaire = createSelector(
  [rootSelector],
  (state) => state.firstQuestionnaires
);

export const getPersonalDetailsQuestionnaire = createSelector(
  [rootSelector],
  (state) => state.personalDetailsQuestionnaire
);

export const getPersonalDetailsPercent = createSelector(
  [rootSelector],
  (state) =>
    state.personalDetailsQuestionnaire?.questionAnswers?.length
      ? Math.trunc(
          (state.personalDetailsQuestionnaire?.questionAnswers?.length /
            state.personalDetailsQuestionnaire.questionsCount) *
            100
        )
      : 0
);

export const isLoadingQuestionnaire = createSelector(
  [rootSelector],
  (state) => state.isLoading
);

export const getHasGoals = createSelector(
  [rootSelector],
  (state) => state.hasGoals
);
