import React from "react";
import { StyledPage, StyledFragment } from "./style";
import { Props } from "./types";

const Page = (props: Props) => {
  const { children, headerContent, footerContent } = props;

  return (
    <StyledPage {...props}>
      <StyledFragment>{headerContent}</StyledFragment>
      <StyledFragment>{children}</StyledFragment>
      <StyledFragment className="footer">{footerContent}</StyledFragment>
    </StyledPage>
  );
};

Page.defaultProps = {
  backgroundGradient: false,
};

export default Page;
