import styled from "styled-components";
import { Props } from "./types";
import { color, space } from "styled-system";

export const StyledListItem = styled.div<Props>`
  ${space}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  color: ${(props) => props.theme.colors.gray[6]};

  ${(props) =>
    props.hasDivider &&
    `border-bottom: 1px solid  ${props.theme.colors.gray[1]}`};

  .icon {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    background: ${(props) => props.iconColor || props.theme.colors.gray[1]};
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }

  .content {
    flex: 1;
    margin: 0 16px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.56;
    ${color}
  }
`;
