import "./style.css";
import React, { useState } from "react";
import Page from "../../components/Page";
import Dropdown from "../../components/Dropdown";
import InputText from "../../components/InputText";
import { useDispatch, useSelector } from "react-redux";
import {
  requestLogin,
  changeLanguage,
  isLoadingLogin,
  getLanguage,
  loginError,
  loginSuccess,
} from "../../store/session";
import BottomModal from "../../components/BottomModal";
import Button from "../../components/Button";
import { LogoImage } from "../../assets/images";
import LanguageItem from "../../components/LanguageItem";
import { BrBrand, EuaBrand, MxBrand } from "../../assets/brands";
import { useTranslation } from "react-i18next";
import api from "../../service/api";
import { User } from "../../store/session/types";

const options = [
  { label: "EN", value: "en", image: EuaBrand },
  { label: "BR", value: "pt", image: BrBrand },
  { label: "ES", value: "es", image: MxBrand },
];

const Login = () => {
  const { t } = useTranslation(["login"]);
  const currentLanguage = useSelector(getLanguage);
  const isLoading = useSelector(isLoadingLogin);
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [loginNotFound, setNotFound] = useState(false);

  const onSelectOption = (option: { value: string }): void => {
    dispatch(changeLanguage({ language: option.value }));
  };

  const login = async () => {
    if (phone.length > 0) {
      dispatch(requestLogin());
      try {
        const response = await api.get<{ user: User }>(
          `fdtUsers/phone/1/${phone}`
        );
        dispatch(loginSuccess(response.data.user));
      } catch (err) {
        dispatch(loginError());
        setNotFound(true);
      }
    }
  };

  const saveNewUser = async () => {
    if (name.length > 0 && zipCode.length > 0) {
      dispatch(requestLogin());
      try {
        const response = await api.post<{ user: User }>("fdtUsers", {
          phone,
          name,
          zipCode,
        });
        dispatch(loginSuccess(response.data.user));
      } catch (err) {
        dispatch(loginError());
      }
    }
  };

  const changeLanguageDropdown = () => (
    <Dropdown
      width={100}
      options={options}
      selectedOption={options.find(
        (option) => option.value === currentLanguage
      )}
      onSelectOption={onSelectOption}
      renderOption={(option) => (
        <LanguageItem
          label={option.label}
          image={option.image}
          active={option.value === currentLanguage}
        />
      )}
      renderSelectedOption={(option, isOpen) => (
        <LanguageItem image={option.image} active={isOpen} />
      )}
    />
  );

  return (
    <Page backgroundGradient>
      <BottomModal marginTop={90}>
        <div className="login-content">
          <div>
            <div className="login-header">
              <img src={LogoImage} alt="LaMedichi" height={31} />
              {changeLanguageDropdown()}
            </div>
            <span className="title-login">{t("welcome")}</span>
            <span className="description-login">{t("singIn")}</span>
          </div>
          <div className="login-input">
            {!loginNotFound ? (
              <>
                <label>{t("phoneLabel")}</label>
                <InputText
                  value={phone}
                  onChangeValue={(value) => setPhone(value.replace(/\D/g, ""))}
                  placeholder={t("phoneInput")}
                />
              </>
            ) : (
              <>
                <label>{t("nameLabel")}</label>
                <InputText
                  mb={4}
                  value={name}
                  onChangeValue={setName}
                  placeholder={t("nameInput")}
                />
                <label>{t("zipCodeLabel")}</label>
                <InputText
                  value={zipCode}
                  onChangeValue={(value) =>
                    setZipCode(value.replace(/\D/g, ""))
                  }
                  placeholder={t("zipCodeInput")}
                />
              </>
            )}
          </div>
          <Button
            value={t("enter")}
            disabled={isLoading}
            onClick={loginNotFound ? saveNewUser : login}
          />
        </div>
      </BottomModal>
    </Page>
  );
};

export default Login;
