import React from "react";
import { StyledActionLink } from "./style";
import { Props } from "./types";

const ActionLink = ({ value, children, ...otherProps }: Props) => (
  <StyledActionLink {...otherProps}>
    {value}
    {children}
  </StyledActionLink>
);

export default ActionLink;
