import styled from "styled-components";
import { space } from "styled-system";
import { Props } from "./types";
import { ThemeProps } from "../../themes/types";

const disabledLayout = (theme: ThemeProps) => `
  background: ${theme.colors.gray[1]};
  color: ${theme.colors.gray[3]};
  padding: 0;
  & img {
    height: 32px;
  }
`;
const secondaryLayout = (theme: ThemeProps) => `
  background: ${theme.colors.white};
  color: ${theme.colors.primary};
  border: solid 1.5px ${theme.colors.primary};
  box-shadow: 0 5px 5px 0 rgba(119, 51, 255, 0.05);
  &:hover {
    box-shadow: 0 5px 5px 0 rgba(119, 51, 255, 0);
    background: ${theme.colors.primarySaturation[1]};
    border-color: transparent;
  }
  &:active {
    box-shadow: 0 5px 5px 0 rgba(119, 51, 255, 0);
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
    border-color: transparent;
  }
`;
const primaryLayout = (theme: ThemeProps) => `
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  box-shadow: 0 5px 25px 0 rgba(119, 51, 255, 0.35);
  &:hover {
    box-shadow: 0 5px 25px 0 rgba(119, 51, 255, 0);
  }
  &:active {
    box-shadow: 0 5px 25px 0 rgba(119, 51, 255, 0);
    background: ${theme.colors.primaryActive};
  }
`;

const getVariants = ({
  disabled,
  secondary,
  theme,
}: Props & { theme: ThemeProps }) => {
  if (disabled) return disabledLayout(theme);
  if (secondary) return secondaryLayout(theme);
  return primaryLayout(theme);
};

const getIconLayout = ({ icon, value }: Props) => {
  if (icon) {
    if (value) {
      return `
        display: flex;
        padding: 0 24px;
        align-items: center;
        justify-content: space-between;
      `;
    }
    return `
      width: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
    `;
  }
};

export const StyledButton = styled.button<Props>`
  ${space};
  width: 100%;
  padding: 0 30px;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 600;
  height: 64px;
  border-radius: 33px;
  border: 0;
  transition: all 300ms;
  cursor: pointer;
  ${(props) => getVariants(props)}
  ${(props) => getIconLayout(props)}

  & img {
    width: 32px;
    height: 32px;
  }
`;
