import React from "react";
import { StyledRadioButton } from "./style";
import { Props } from "./types";

const RadioButton = (props: Props) => {
  const { value, image } = props;

  return (
    <StyledRadioButton {...props}>
      {image && <img height={32} width={32} src={image} alt={value} />}
      {value}
    </StyledRadioButton>
  );
};

export default RadioButton;
