import styled from "styled-components";
import { space } from "styled-system";
import { ThemeProps } from "../../themes/types";

const primaryLayout = ({ theme }: { theme: ThemeProps }) => `
  background: ${theme.colors.white};
  color: ${theme.colors.primary};
  &:hover {
    background: ${theme.colors.primarySaturation[1]};
  }
  &:active {
    background: ${theme.colors.primarySaturation[0]};
    border: solid 1px ${theme.colors.primary};
  }
`;

export const StyledActionLink = styled.button`
  ${space}
  font-size: 16px;
  font-weight: 500;
  line-height: 1.56;
  border-radius: 8px;
  font-family: Poppins;
  text-decoration: underline;
  padding: 8px 16px;
  cursor: pointer;
  border: solid 1px transparent;
  ${(props) => primaryLayout(props)}
`;
