import React from "react";
import { StyledCountQuestions } from "./style";
import { Props } from "./types";

const CountQuestions = (props: Props) => {
  const { value } = props;

  return <StyledCountQuestions {...props}>{value}</StyledCountQuestions>;
};

export default CountQuestions;
