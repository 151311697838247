import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";
import { SessionState, User } from "./types";

/** Initial state */
export const INITIAL_STATE: SessionState = {
  user: undefined,
  language: "es",
  isLoading: false,
};

/** Action creators */
export const changeLanguage = createAction<{ language: string }>(
  "CHANGE_LOGIN"
);
export const requestLogin = createAction("REQUEST_LOGIN");
export const loginSuccess = createAction<User>("LOGIN_SUCCESS");
export const loginError = createAction("LOGIN_ERROR");

/** Reducer */
export default createReducer(INITIAL_STATE, {
  [changeLanguage.type]: (state, action): SessionState => ({
    ...state,
    language: action.payload.language,
  }),
  [loginSuccess.type]: (state, action): SessionState => ({
    ...state,
    user: action.payload,
    isLoading: false,
  }),
  [requestLogin.type]: (state): SessionState => ({
    ...state,
    isLoading: true,
  }),
  [loginError.type]: (state): SessionState => ({
    ...state,
    isLoading: false,
  }),
});

/** Selectors */
const rootSelector = (state: any): SessionState => state.session;

export const getLanguage = createSelector(
  [rootSelector],
  (session) => session.language
);

export const getUser = createSelector(
  [rootSelector],
  (session) => session.user
);

export const isLoadingLogin = createSelector(
  [rootSelector],
  (session) => session.isLoading
);
