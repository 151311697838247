import styled from "styled-components";
import { space } from "styled-system";
import ReactSlider from "react-slider";

export const StyledSlider = styled(ReactSlider)`
  ${space}
  width: 100%;
`;

export const StyledThumb = styled.div`
  font-size: 1px;
  height: 32px;
  line-height: 25px;
  width: 32px;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.white};
  border: 2px solid ${(props) => props.theme.colors.primary};
  border-radius: 50%;
  cursor: grab;
`;

export const StyledTrack = styled.div<{ index: number }>`
  top: 0;
  bottom: 0;
  background: ${(props) =>
    props.index === 1
      ? props.theme.colors.primary
      : props.theme.colors.gray[1]};
  border-radius: 1px;
  margin-top: 16px;
  height: 4px;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledRow = styled.div`
  display: flex;
  margin-top: 24px;
  flex-direction: row;
  align-items: center;
`;

export const StyledLabel = styled.label`
  width: 80px;
  color: #757575;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  font-style: normal;
  font-stretch: normal;
  font-family: Poppins;
`;
