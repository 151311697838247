import React from "react";
import "./style.css";
import Page from "../../components/Page";
import { useHistory } from "react-router-dom";
import ButtonIcon from "../../components/ButtonIcon";
import ListItem from "../../components/ListItem";
import { AllImages } from "../../assets/emojis";
import { useDispatch, useSelector } from "react-redux";
import {
  getExpensesQuestionnaires,
  selectQuestionnaire,
  getTotalExpenses,
} from "../../store/questionnaire";
import { Questionnaire } from "../../models/Questionnaire";
import { QuestionnaireAnswer } from "../../models/QuestionnaireAnswer";
import MaskHelper from "../../helpers/mask";
import { useTranslation } from "react-i18next";

const ListCategories = () => {
  const { t } = useTranslation(["listCategories"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const questionnaires = useSelector(getExpensesQuestionnaires);
  const totalExpenses = useSelector(getTotalExpenses);

  const onClickQuestionnaire = (questionnaire: Questionnaire): void => {
    dispatch(selectQuestionnaire(questionnaire));
    history.push("set-expenses");
  };

  const sumAllAnswers = (questionAnswers: QuestionnaireAnswer[]) =>
    questionAnswers.reduce(
      (total, questionAnswer) => total + (questionAnswer.currency || 0),
      0
    );

  return (
    <Page
      headerContent={
        <>
          <ButtonIcon
            icon="arrow"
            rotate={90}
            onClick={() => history.push("questionnaires")}
          />

          <div className="container-list-categories">
            <div className="title">{t("title")}</div>
            <div className="description">{t("description")}</div>
          </div>
        </>
      }
      footerContent={
        <div className="footer-list-categories">
          <div className="label">{t("total")}</div>
          <div className="value">
            <span>$</span>
            {MaskHelper.currency(totalExpenses)}
          </div>
        </div>
      }
    >
      <div className="container-list-categories">
        {questionnaires.map((questionnaire) => (
          <ListItem
            key={questionnaire.id}
            hasDivider
            iconColor="#ffe6e6"
            icon={AllImages[questionnaire.emoji] || ""}
            sideContent={
              <ButtonIcon
                icon="arrow"
                rotate={270}
                onClick={() => onClickQuestionnaire(questionnaire)}
              />
            }
          >
            <div>{questionnaire.name}</div>
            {!!questionnaire.questionAnswers?.length && (
              <div className="expenseValue">
                {MaskHelper.currency(
                  sumAllAnswers(questionnaire.questionAnswers || []),
                  "$"
                )}
              </div>
            )}
          </ListItem>
        ))}
      </div>
    </Page>
  );
};

export default ListCategories;
