import React, { useEffect } from "react";
import Welcome from "../pages/Welcome";
import { Route, Switch, useHistory } from "react-router-dom";
import Login from "../pages/Login";
import FirstQuestionnaire from "../pages/FirstQuestionnaire";
import Nudger from "../pages/Nudge";
import Questionnaires from "../pages/Questionnaires";
import ProfileResult from "../pages/ProfileResult";
import Result from "../pages/Result";
import PersonalDetails from "../pages/PersonalDetails";
import SetIncomes from "../pages/SetIncomes";
import ListIncomes from "../pages/ListIncomes";
import ListCategories from "../pages/ListCategories";
import SetExpenses from "../pages/SetExpenses";
import { useSelector } from "react-redux";
import { getUser, getLanguage } from "../store/session";
import { useTranslation } from "react-i18next";

const Routes = () => {
  const { i18n } = useTranslation(["login"]);
  const history = useHistory();
  const user = useSelector(getUser);
  const currentLanguage = useSelector(getLanguage);

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, [i18n, currentLanguage]);

  useEffect(() => {
    if (user) history.push("welcome");
    else history.push("");
  }, [history, user]);

  return (
    <Switch>
      <Route path="/list-categories" component={ListCategories} />
      <Route path="/list-incomes" component={ListIncomes} />
      <Route path="/set-expenses" component={SetExpenses} />
      <Route path="/set-incomes" component={SetIncomes} />
      <Route path="/personal-details" component={PersonalDetails} />
      <Route path="/result" component={Result} />
      <Route path="/profile-result" component={ProfileResult} />
      <Route path="/questionnaires" component={Questionnaires} />
      <Route path="/nudge" component={Nudger} />
      <Route path="/start" component={FirstQuestionnaire} />
      <Route path="/welcome" component={Welcome} />
      <Route path="/" component={Login} />
    </Switch>
  );
};

export default Routes;
