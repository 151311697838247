import styled from "styled-components";
import { ThemeProps } from "../../themes/types";
import { Props } from "./types";

export const StyledHeader = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;

  .avatar {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    border-radius: 8px;
    background: #fff7e6;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
      object-fit: contain;
    }
  }

  .details {
    flex: 1;
    display: flex;
    white-space: nowrap;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
      text-align: left;
      color: ${(props: { theme: ThemeProps }) => props.theme.colors.white};
    }

    .description {
      opacity: 0.8;
      font-family: Poppins;
      font-size: 12px;
      line-height: 1.33;
      text-align: left;
      color: ${(props: { theme: ThemeProps }) => props.theme.colors.white};
    }
  }

  .sideContent {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
`;
