import React, { useState, useEffect, useCallback } from "react";
import "./style.css";
import Page from "../../components/Page";
import Button from "../../components/Button";
import { Question } from "../../models/Question";
import Title from "../../components/Title";
import { useHistory } from "react-router-dom";
import CountQuestions from "../../components/CountQuestions";
import ButtonIcon from "../../components/ButtonIcon";
import ListItem from "../../components/ListItem";
import { MoneyImage } from "../../assets/emojis";
import RadioButton from "../../components/RadioButton";
import InputText from "../../components/InputText";
import { useDispatch, useSelector } from "react-redux";
import {
  getIncomeQuestionnaire,
  getQuestion,
  requestQuestion,
  questionSuccess,
  updateIncomeQuestionnaireAnswers,
  isLoadingQuestionnaire,
} from "../../store/questionnaire";
import { getUser } from "../../store/session";
import api from "../../service/api";
import { Option } from "../../models/Option";
import { QuestionnaireAnswer } from "../../models/QuestionnaireAnswer";
import InputRange from "../../components/InputRange";
import LineGradient from "../../components/LineGradient";
import { useTranslation } from "react-i18next";
import { getIcons } from "../../assets/icons";
import Spinner from "../../components/Spinner";
import theme from "../../themes/main";

const SetIncomes = () => {
  const { t } = useTranslation(["setIncomes"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector(isLoadingQuestionnaire);
  const questionnaire = useSelector(getIncomeQuestionnaire);
  const [lastQuestions, setLastQuestions] = useState<Question[]>([]);
  const [value, setValue] = useState<any>(0);
  const [setId, updateSetId] = useState<any>(0);
  const [selectedOption, setSelectedOption] = useState<Option>();
  const user = useSelector(getUser);
  const question = useSelector(getQuestion);

  const onLoad = useCallback(async () => {
    if (questionnaire) dispatch(questionSuccess(questionnaire.FirstQuestion));
  }, [dispatch, questionnaire]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const submitQuestion = async () => {
    if (question) setLastQuestions([...lastQuestions, question]);
    dispatch(requestQuestion());
    const response = await api.put<{ nextQuestion: Question; setId?: number }>(
      "questionAnswers",
      {
        optionId: selectedOption ? selectedOption.id : question?.Options[0].id,
        userId: user?.id,
        body: question?.type === "text" ? value : undefined,
        currency: question?.type === "range" ? parseInt(value) : undefined,
        setId,
      }
    );

    setValue("");
    setSelectedOption(undefined);
    if (response.data.nextQuestion) {
      response.data.nextQuestion?.Options?.sort(
        (a, b) => (a?.order || 0) - (b?.order || 0)
      );
      dispatch(questionSuccess(response.data.nextQuestion));
      updateSetId(response.data.setId);
    } else {
      const responseAnswer = await api.get<{
        questionnaireAnswers: QuestionnaireAnswer[];
      }>("questionnaireAnswers", {
        params: {
          userId: user?.id,
          questionnaireId: questionnaire?.id,
        },
      });
      dispatch(
        updateIncomeQuestionnaireAnswers(
          responseAnswer.data.questionnaireAnswers
        )
      );
      history.push("list-incomes");
    }
  };

  const returnQuestion = () => {
    if (questionnaire?.questionAnswers?.length) history.goBack();
    else history.push("questionnaires");
  };

  const backQuestion = () => {
    const last = lastQuestions.pop();
    if (last) dispatch(questionSuccess(last));
  };

  return (
    <Page
      headerContent={
        <>
          <div className="returnButtons">
            <ButtonIcon icon="arrow" rotate={90} onClick={returnQuestion} />
            {lastQuestions.length > 0 && (
              <button className="returnButtonSecondary" onClick={backQuestion}>
                <img src={getIcons("return-gray")} alt="return" />
                {t("return")}
              </button>
            )}
          </div>
          <ListItem my={24} iconColor="#e6ffee" icon={MoneyImage}>
            {t("incomes")}
          </ListItem>
          {!isLoading && (
            <div>
              <CountQuestions
                color="primary"
                value={t("counter", {
                  current: lastQuestions.length + 1,
                  total:
                    lastQuestions.length > (questionnaire?.questionsCount || 0)
                      ? lastQuestions.length
                      : questionnaire?.questionsCount,
                })}
              />
              <Title value={question?.body} color="gray.6" />
            </div>
          )}
        </>
      }
      footerContent={
        <>
          {!isLoading && (
            <div className="footer-set-incomes">
              <Button
                icon="arrow-right"
                className="float-button"
                disabled={!value && !selectedOption}
                onClick={() => submitQuestion()}
              />
            </div>
          )}
        </>
      }
    >
      {isLoading ? (
        <div className="loadingQuestionnaire">
          <Spinner size={32} color={theme.colors.primary} top={200} left={30} />
        </div>
      ) : (
        <div className="question-container">
          {question?.type === "text" && (
            <InputText onChangeValue={setValue} value={value} />
          )}
          {question?.type === "range" && (
            <>
              <div className="rangerLabel">{t("setAverageRange")}</div>
              <InputRange
                onChange={(values) => {
                  setValue((values[0] + values[1]) / 2);
                }}
              />
            </>
          )}
          {question?.type === "single_choice" &&
            question?.Options?.map((option) => (
              <RadioButton
                mb={2}
                key={option.id}
                value={option.body}
                selected={option.id === selectedOption?.id}
                onClick={() => setSelectedOption(option)}
              />
            ))}
        </div>
      )}
      <LineGradient />
    </Page>
  );
};

export default SetIncomes;
