import React, { useEffect } from "react";
import "./style.css";
import Page from "../../components/Page";
import { useHistory } from "react-router-dom";
import ButtonIcon from "../../components/ButtonIcon";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  getIncomeValues,
  getTotalIncomes,
  getIncomeQuestionnaire,
  updateIncomeQuestionnaireAnswers,
} from "../../store/questionnaire";
import ListItem from "../../components/ListItem";
import { MoneyImage } from "../../assets/emojis";
import MaskHelper from "../../helpers/mask";
import api from "../../service/api";
import { getUser } from "../../store/session";
import { QuestionnaireAnswer } from "../../models/QuestionnaireAnswer";
import { useTranslation } from "react-i18next";
import LineGradient from "../../components/LineGradient";

const ListIncomes = () => {
  const { t } = useTranslation(["listIncomes"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const incomes = useSelector(getIncomeValues);
  const totalIncomes = useSelector(getTotalIncomes);
  const user = useSelector(getUser);
  const questionnaire = useSelector(getIncomeQuestionnaire);

  useEffect(() => {
    if (!questionnaire?.questionAnswers?.length) history.push("set-incomes");
  }, [history, questionnaire]);

  const deleteIncome = async (income: QuestionnaireAnswer) => {
    await api.delete("questionnaireAnswers", {
      params: {
        userId: user?.id,
        setId: income.setId,
        questionnaireId: questionnaire?.id,
      },
    });

    if (questionnaire?.questionAnswers)
      dispatch(
        updateIncomeQuestionnaireAnswers(
          questionnaire?.questionAnswers?.filter(
            (questionAnswer) => questionAnswer.setId !== income.setId
          )
        )
      );
  };

  return (
    <Page
      headerContent={
        <>
          <ButtonIcon
            icon="arrow"
            rotate={90}
            onClick={() => history.push("questionnaires")}
          />
          <div className="title-list-incomes">{questionnaire?.name}</div>

          {incomes?.map((income, index) => (
            <ListItem
              key={index}
              hasDivider
              iconColor="#e6ffee"
              icon={MoneyImage}
              sideContent={
                <ButtonIcon
                  icon="exit-gray"
                  onClick={() => deleteIncome(income)}
                />
              }
            >
              <div className="incomeBody">{income.body}</div>
              <div className="incomeValue">
                {MaskHelper.currency(income.currency || 0, "$")}
              </div>
            </ListItem>
          ))}
        </>
      }
      footerContent={
        <div className="footer-list-incomes">
          <div className="label">{t("total")}</div>
          <div className="value">
            <span>$</span>
            {MaskHelper.currency(totalIncomes || 0)}
          </div>
          <LineGradient />
        </div>
      }
    >
      <div className="question-container">
        <Button
          icon="exit"
          className="addIncomeButton"
          onClick={() => history.push("set-incomes")}
        />
      </div>
    </Page>
  );
};

export default ListIncomes;
