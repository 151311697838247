import React from "react";
import { StyledLanguageItem } from "./style";
import { Props } from "./types";

const LanguageItem = (props: Props) => {
  const { children, image, label } = props;
  return (
    <StyledLanguageItem {...props}>
      {image && <div className="brand" />}
      {label && <label>{label}</label>}
      {children}
    </StyledLanguageItem>
  );
};

LanguageItem.defaultProps = {
  disabled: false,
  active: false,
};

export default LanguageItem;
