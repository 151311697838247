import styled from "styled-components";
import { space } from "styled-system";

export const StyledInputText = styled.input`
  ${space}
  color: ${(props) => props.theme.colors.gray[6]};
  border: none;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.51;
  text-align: left;
  overflow: hidden;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray[2]};
  max-width: 100%;
  width: 100%;

  &::placeholder {
    color: ${(props) => props.theme.colors.gray[2]};
    opacity: 1;
  }

  &:focus {
    outline: none;
    border-bottom: 2px solid
      ${(props) => props.theme.colors.primarySaturation[0]};
  }
`;
