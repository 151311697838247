import yes from "./ok.png";
import no from "./no.png";
import user from "./user.png";
import clipboard from "./clipboard.png";
import money from "./money.png";
import moneyFly from "./money-fly.png";
import house from "./house.png";
import expenses from "./expenses.png";
import car from "./car.png";
import daily from "./daily.png";
import family from "./family.png";
import entertainment from "./entertainment.png";
import health from "./health.png";
import taxes from "./taxes.png";
import education from "./education.png";
import debt from "./debt.png";

export const YesImage = yes;
export const NoImage = no;
export const UserImage = user;
export const ClipboardImage = clipboard;
export const MoneyFlyImage = moneyFly;
export const MoneyImage = money;
export const CarImage = car;
export const HouseImage = house;
export const ExpensesImage = expenses;

export const AllImages: any = {
  yes,
  no,
  user,
  clipboard,
  money,
  moneyFly,
  car,
  house,
  expenses,
  daily,
  family,
  entertainment,
  health,
  home: house,
  taxes,
  education,
  others: moneyFly,
  debt,
};
