import styled from "styled-components";
import { space } from "styled-system";
import { ThemeProps } from "../../themes/types";

export const StyledDropdown = styled.div<{ width?: number }>`
  ${space};
  width: ${(props) => props.width}px;
  position: relative;
`;

export const StyledSelectedOption = styled.div`
  height: 48px;
  border-radius: 8px;
  overflow: hidden;
  border: solid 1px ${(props) => props.theme.colors.gray[2]};
  position: relative;
  cursor: pointer;

  & img {
    position: absolute;
    right: 12px;
    top: 8px;
  }

  &:hover {
    background: ${(props: { theme: ThemeProps }) =>
      props.theme.colors.primarySaturation[1]};
  }
`;

export const StyledOption = styled.div``;

export const StyledOptionContainer = styled.div<{ width?: number }>`
  position: absolute;
  top: 58px;
  padding: 16px 0;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(119, 51, 255, 0.1); */
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(119,51,255,0.1);
  background: ${(props) => props.theme.colors.white};
  overflow: hidden;
  width: ${(props) => props.width}px;
`;
