import React, { useState, useEffect, useCallback } from "react";
import Page from "../../components/Page";
import Title from "../../components/Title";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import { useHistory } from "react-router-dom";
import { getUser } from "../../store/session";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getQuestion,
  questionSuccess,
  getFirstQuestionnaire,
} from "../../store/questionnaire";
import {
  fetchExpensesQuestionnaires,
  fetchFirstQuestionnaire,
  fetchIncomesQuestionnaire,
  fetchPersonalQuestionnaire,
} from "../../service/session";

const Welcome = () => {
  const { t, i18n } = useTranslation(["welcome"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showStartPage, setShowStatePage] = useState(false);
  const [isLoadingQuestionnaires, setLoadingQuestionnaires] = useState(true);
  const firstQuestionnaire = useSelector(getFirstQuestionnaire);
  const user = useSelector(getUser);
  const firstQuestion = useSelector(getQuestion);

  const onLoad = useCallback(async () => {
    try {
      setLoadingQuestionnaires(true);
      if (user?.id) {
        await fetchFirstQuestionnaire(dispatch, user.id, i18n.language);
        await fetchPersonalQuestionnaire(dispatch, user.id, i18n.language);
        await fetchIncomesQuestionnaire(dispatch, user.id, i18n.language);
        await fetchExpensesQuestionnaires(dispatch, user.id, i18n.language);
      }
      setLoadingQuestionnaires(false);
    } catch (err) {
      setLoadingQuestionnaires(false);
      console.error("Error on load questionnaires");
    }
  }, [dispatch, user, i18n]);

  useEffect(() => {
    onLoad();
    const timer = setTimeout(() => {
      setShowStatePage(true);
    }, 4000);
    return () => clearTimeout(timer);
  }, [onLoad]);

  useEffect(() => {
    if (
      !isLoadingQuestionnaires &&
      firstQuestionnaire?.questionAnswers?.length &&
      firstQuestionnaire.questionAnswers.length > 10
    )
      history.push("questionnaires");
  }, [history, isLoadingQuestionnaires, firstQuestionnaire]);

  useEffect(() => {
    if (!!firstQuestion) history.push("start");
  }, [history, firstQuestion]);

  const start = async () => {
    if (firstQuestionnaire)
      dispatch(questionSuccess(firstQuestionnaire.FirstQuestion));
  };

  return (
    <Page
      backgroundGradient
      footerContent={
        showStartPage &&
        !isLoadingQuestionnaires && (
          <Button value={t("start")} onClick={start} />
        )
      }
    >
      {isLoadingQuestionnaires || !showStartPage ? (
        <>
          <Title value={t("welcome-user", { name: user?.name })} />
          <Title value={t("welcome-description")} />
          <Spinner />
        </>
      ) : (
        <Title value={t("start-title")} />
      )}
    </Page>
  );
};

export default Welcome;
