import React, { useCallback, useEffect, useState } from "react";
import "./style.css";
import Page from "../../components/Page";
import Title from "../../components/Title";
import Spinner from "../../components/Spinner";
import { useHistory } from "react-router-dom";
import ListItem from "../../components/ListItem";
import { AllImages } from "../../assets/emojis";
import { useDispatch, useSelector } from "react-redux";
import {
  getHighestCategory,
  getTotalExpenses,
  updateHasGoals,
} from "../../store/questionnaire";
import { useTranslation } from "react-i18next";
import { getUser } from "../../store/session";
import api from "../../service/api";

const ProfileResult = () => {
  const { t } = useTranslation(["profileResult"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getUser);
  const highestCategory = useSelector(getHighestCategory);
  const total = useSelector(getTotalExpenses);
  const [percent, setPercent] = useState(0);
  const [loading, setLoading] = useState(false);

  const requestGoals = useCallback(async () => {
    setLoading(true);
    const response = await api.get<{ goals: any[] }>("goals", {
      params: {
        userId: user?.id,
      },
    });

    dispatch(updateHasGoals({ hasGoals: !!response?.data?.goals.length }));
  }, [dispatch, user]);

  useEffect(() => {
    if (!loading) requestGoals();
    setPercent(Math.trunc((highestCategory.value / total) * 100));
    setTimeout(() => {
      history.push("result");
    }, 5000);
  }, [requestGoals, loading, history, highestCategory, total]);

  return (
    <Page backgroundGradient>
      <>
        <Title value={highestCategory.nudge} />
        <div className="profile-result-medium-text">{t("description")}</div>
        <ListItem
          icon={AllImages[highestCategory.emoji]}
          iconColor="#c4d0ec"
          color="white"
        >
          <div className="profile-result-text">
            {highestCategory.name}
            <div className="profile-result-small-text">
              {t("percentCategory", { percent })}
            </div>
          </div>
        </ListItem>
        <Spinner />
      </>
    </Page>
  );
};

export default ProfileResult;
