import styled from "styled-components";
import { Props } from "./types";
import { space } from "styled-system";

export const StyledProgressBar = styled.div<Props>`
  ${space}
  height: 5px;
  width: 100%;
  display: flex;
  justify-content: start;
  border-radius: 28px;
  background: ${(props) => props.theme.colors.primarySaturation[0]};
  position: relative;
  overflow: hidden;

  .progress {
    height: 5px;
    background: ${(props) => props.theme.colors.secondary};
    transition: all 300ms;
    width: ${(props) => (props.current * 100) / props.total}%;
  }
`;
