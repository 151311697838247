import React from "react";
import { StyledHeader } from "./style";
import { Props } from "./types";
import { UserImage } from "../../assets/emojis";

const Header = (props: Props) => {
  const { title, description, sideContent } = props;

  return (
    <StyledHeader {...props}>
      <div className="avatar">
        <img src={UserImage} alt="Avatar User" />
      </div>

      <div className="details">
        <span className="title">{title}</span>
        <span className="description">{description}</span>
      </div>

      <div className="sideContent">{sideContent}</div>
    </StyledHeader>
  );
};

Header.defaultProps = {};

export default Header;
