import { Dispatch } from "redux";
import { Questionnaire } from "../../models/Questionnaire";
import { questionnairesSuccess } from "../../store/questionnaire";
import api from "../api";

export const fetchFirstQuestionnaire = async (
  dispatch: Dispatch<any>,
  userId: number,
  language: string
): Promise<void> => {
  try {
    const response = await api.get<{ questionnaires: Questionnaire[] }>("questionnaires", {
      params: {
        type: 1,
        userId,
        language,
      },
    });

    dispatch(
      questionnairesSuccess({
        firstQuestionnaires: response.data.questionnaires[0],
      })
    );
  } catch (err) {
    console.error("Error on load questionnaires");
  }
};

export const fetchPersonalQuestionnaire = async (
  dispatch: Dispatch<any>,
  userId: number,
  language: string
): Promise<void> => {
  try {
    const response = await api.get<{ questionnaires: Questionnaire[] }>("questionnaires", {
      params: {
        type: 2,
        userId,
        language,
      },
    });

    dispatch(
      questionnairesSuccess({
        personalDetailsQuestionnaire: response.data.questionnaires[0],
      })
    );
  } catch (err) {
    console.error("Error on load questionnaires");
  }
};

export const fetchIncomesQuestionnaire = async (
  dispatch: Dispatch<any>,
  userId: number,
  language: string
): Promise<void> => {
  try {
    const response = await api.get<{ questionnaires: Questionnaire[] }>("questionnaires", {
      params: {
        type: 3,
        userId,
        language,
      },
    });

    dispatch(
      questionnairesSuccess({
        incomesQuestionnaire: response.data.questionnaires[0],
      })
    );
  } catch (err) {
    console.error("Error on load questionnaires");
  }
};

export const fetchExpensesQuestionnaires = async (
  dispatch: Dispatch<any>,
  userId: number,
  language: string
): Promise<void> => {
  try {
    const response = await api.get<{ questionnaires: Questionnaire[] }>("questionnaires", {
      params: {
        type: 4,
        userId,
        language,
      },
    });
    dispatch(
      questionnairesSuccess({
        expensesQuestionnaires: response.data.questionnaires,
      })
    );
  } catch (err) {
    console.error("Error on load questionnaires");
  }
};
