import React from "react";
import { StyledInputNumber, StyledContainer } from "./style";
import { Props } from "./types";
import ButtonIcon from "../ButtonIcon";

const InputNumber = ({ value, onChangeValue }: Props) => {
  const onPressLess = () => {
    if (value && value > 0) {
      onChangeValue(value - 1);
    }
  };

  const onPressPlus = () => {
    onChangeValue((value || 0) + 1);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChangeValue(parseInt(e.target.value, 10));
  };

  return (
    <StyledContainer>
      <ButtonIcon icon="less" mr={2} onClick={onPressLess} />
      <StyledInputNumber
        onChange={onChange}
        placeholder="- -"
        type="number"
        value={value}
      />
      <ButtonIcon icon="plus" ml={2} onClick={onPressPlus} />
    </StyledContainer>
  );
};

export default InputNumber;
