import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import pt from "./pt.json";
import es from "./es.json";

i18next.use(initReactI18next).init({
  resources: {
    es,
    en,
    pt,
  },
  lng: "es",
  fallbackLng: "es",
  interpolation: {
    escapeValue: false,
  },
});
