import React, { useCallback, useEffect } from "react";
import Page from "../../components/Page";
import Title from "../../components/Title";
import Spinner from "../../components/Spinner";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../store/session";
import { Questionnaire } from "../../models/Questionnaire";
import api from "../../service/api";
import { useTranslation } from "react-i18next";
import { questionnairesSuccess } from "../../store/questionnaire";
import {
  fetchPersonalQuestionnaire,
} from "../../service/session";

const Nudger = () => {
  const { i18n } = useTranslation(["welcome"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation<{ nudge: string }>();
  const user = useSelector(getUser);

  const onLoad = useCallback(async () => {
    try {
      if (user?.id) {
        await fetchPersonalQuestionnaire(dispatch, user.id, i18n.language);
      }
    } catch (err) {
      console.error("Error on load questionnaires");
    }
  }, [dispatch, user, i18n]);

  const requestExpensesQuestionnaires = useCallback(async () => {
    try {
      const response = await api.get<{ questionnaires: Questionnaire[] }>(
        "questionnaires",
        {
          params: {
            type: 4,
            userId: user?.id,
            language: i18n.language,
          },
        }
      );
      dispatch(
        questionnairesSuccess({
          expensesQuestionnaires: response.data.questionnaires,
        })
      );
    } catch (err) {
      console.error("Error on load questionnaires");
    }
  }, [user, dispatch, i18n]);

  useEffect(() => {
    onLoad();
    requestExpensesQuestionnaires();
    setTimeout(() => {
      history.push("questionnaires");
    }, 3000);
  }, [history, requestExpensesQuestionnaires, onLoad]);

  return (
    <Page backgroundGradient>
      <>
        <Title value={location?.state.nudge} />
        <Spinner />
      </>
    </Page>
  );
};

export default Nudger;
