import React, { useState, useEffect, useCallback } from "react";
import "./style.css";
import Page from "../../components/Page";
import Button from "../../components/Button";
import { Question } from "../../models/Question";
import Title from "../../components/Title";
import { useHistory } from "react-router-dom";
import CountQuestions from "../../components/CountQuestions";
import ButtonIcon from "../../components/ButtonIcon";
import ListItem from "../../components/ListItem";
import { ClipboardImage } from "../../assets/emojis";
import InputNumber from "../../components/InputNumber";
import ActionLink from "../../components/ActionLink";
import RadioButton from "../../components/RadioButton";
import InputText from "../../components/InputText";
import { useDispatch, useSelector } from "react-redux";
import {
  getPersonalDetailsQuestionnaire,
  getQuestion,
  questionnairesSuccess,
  questionSuccess,
  requestQuestion,
  isLoadingQuestionnaire,
} from "../../store/questionnaire";
import { getUser } from "../../store/session";
import api from "../../service/api";
import { useTranslation } from "react-i18next";
import LineGradient from "../../components/LineGradient";
import Spinner from "../../components/Spinner";
import theme from "../../themes/main";

const PersonalDetails = () => {
  const { t } = useTranslation(["personalDetails"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector(isLoadingQuestionnaire);
  const questionnaire = useSelector(getPersonalDetailsQuestionnaire);
  const [lastQuestions, setLastQuestions] = useState<Question[]>([]);
  const [value, setValue] = useState<any>();
  const user = useSelector(getUser);
  const question = useSelector(getQuestion);

  const onLoad = useCallback(async () => {
    if (questionnaire) dispatch(questionSuccess(questionnaire?.FirstQuestion));
  }, [dispatch, questionnaire]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const submitQuestion = async () => {
    if (question) setLastQuestions([...lastQuestions, question]);
    dispatch(requestQuestion());
    const response = await api.put<{ nextQuestion: Question }>(
      "questionAnswers",
      {
        optionId: value?.id || question?.Options[0].id,
        userId: user?.id,
        body: !value?.id && value,
      }
    );

    dispatch(
      questionnairesSuccess({
        personalDetailsQuestionnaire: questionnaire && {
          ...questionnaire,
          questionAnswers: questionnaire?.questionAnswers
            ? [...questionnaire?.questionAnswers, { user: user?.id }]
            : [{ user: user?.id }],
        },
      })
    );
    setValue(undefined);
    if (response.data.nextQuestion)
      dispatch(questionSuccess(response.data.nextQuestion));
    else {
      history.push("questionnaires");
    }
  };

  const returnQuestion = () => {
    history.goBack();
  };

  return (
    <Page
      headerContent={
        <>
          <ButtonIcon icon="arrow" rotate={90} onClick={returnQuestion} />
          <ListItem my={24} iconColor="#f0e6ff" icon={ClipboardImage}>
            {questionnaire?.name}
          </ListItem>

          {!isLoading && (
              <React.Fragment>
                <CountQuestions
                    color="primary"
                    value={t("counter", {
                      current: lastQuestions.length + 1,
                      total:
                          lastQuestions.length > (questionnaire?.questionsCount || 0)
                              ? lastQuestions.length
                              : questionnaire?.questionsCount,
                    })}
                />
                <Title value={question?.body} color="gray.6" />
            </React.Fragment>
          )}

        </>
      }
      footerContent={
        <>
          {!isLoading && (
            <div className="footer">
              <ActionLink value={t("skip")} onClick={() => submitQuestion()} />
              <Button
                icon="arrow-right"
                disabled={value === undefined}
                onClick={submitQuestion}
              />
            </div>
            )}
        </>
      }
    >

      {isLoading && (
              <div className="loadingQuestionnaire">
                <Spinner size={32} color={theme.colors.primary} top={200} left={30} />
              </div>
      )}

      {question?.type === "text" && (
        <InputText onChangeValue={setValue} value={value} />
      )}
      {question?.type === "number" && (
        <InputNumber onChangeValue={setValue} value={value} />
      )}
      {question?.type === "single_choice" &&
        question?.Options?.map((option) => (
          <RadioButton
            value={option.body}
            selected={option.id === value?.id}
            mb={2}
            onClick={() => setValue(option)}
          />
        ))}
      <LineGradient />
    </Page>
  );
};

export default PersonalDetails;
