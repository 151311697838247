import React from "react";
import { StyledButton } from "./style";
import { Props } from "./types";
import { getIcons, getDisabledIcons } from "../../assets/icons";

const Button = (props: Props) => {
  const { children, value, icon, disabled } = props;

  return (
    <StyledButton {...props}>
      {value}
      {children}
      {icon && (
        <img
          src={disabled ? getDisabledIcons(icon) : getIcons(icon)}
          alt={value}
        />
      )}
    </StyledButton>
  );
};

export default Button;
