import styled from "styled-components";
import { space } from "styled-system";
import { Props } from "./types";
import { ThemeProps } from "../../themes/types";

const disabledLayout = (theme: ThemeProps) => `
  background: ${theme.colors.gray[1]};
  color: ${theme.colors.gray[3]};
`;
const primaryLayout = (theme: ThemeProps) => `
  background: transparent;
  &:hover {
    background: ${theme.colors.primarySaturation[1]};
    border-color: ${theme.colors.primarySaturation[1]};
  }
  &:active {
    background: ${theme.colors.primarySaturation[0]};
    border-color: ${theme.colors.primary};
  }
`;

const getVariants = ({ disabled, theme }: Props & { theme: ThemeProps }) => {
  if (disabled) return disabledLayout(theme);
  return primaryLayout(theme);
};

export const StyledButtonIcon = styled.button<Props>`
  ${space};
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: solid 1px ${(props) => props.theme.colors.gray[3]};
  transition: all 300ms;
  transform: rotate(${(props) => props.rotate}deg);
  cursor: pointer;
  ${(props) => getVariants(props)}

  img {
    height: 24px;
    width: 24px;
  }
`;
