import styled from "styled-components";
import { Props } from "./types";

export const StyledColumnSelector = styled.div<Props>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
`;
export const StyledColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.gray[6]};
  text-align: center;
  padding-bottom: 64px;
  cursor: pointer;
  transition: all 300ms;

  & img {
    width: 36px;
    height: 36px;
    object-fit: contain;
  }

  &:hover {
    background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0)
    );
  }
`;
