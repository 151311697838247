import styled from "styled-components";

export const StyledInputMoney = styled.input`
  color: ${(props) => props.theme.colors.gray[6]};
  border: none;
  font-family: Poppins;
  font-size: 45px;
  font-weight: 500;
  line-height: 1.51;
  text-align: center;
  overflow: hidden;
  border-bottom: 5px dashed ${(props) => props.theme.colors.gray[2]};
  max-width: 100%;

  &::placeholder {
    color: ${(props) => props.theme.colors.gray[2]};
    opacity: 1;
  }

  &:focus {
    outline: none;
    border-bottom: 5px dashed
      ${(props) => props.theme.colors.primarySaturation[0]};
  }
`;
