import React from "react";
import { StyledColumnSelector, StyledColumn } from "./style";
import { Props } from "./types";
import { NoImage, YesImage } from "../../assets/emojis";

const ColumnSelector = (props: Props) => {
  const { handlerYes, handlerNo, labelNo, labelYes } = props;

  return (
    <StyledColumnSelector {...props}>
      <StyledColumn onClick={handlerNo}>
        <div>
          {labelNo} <img src={NoImage} alt="no" />
        </div>
      </StyledColumn>
      <StyledColumn onClick={handlerYes}>
        <div>
          {labelYes} <img src={YesImage} alt="yes" />
        </div>
      </StyledColumn>
    </StyledColumnSelector>
  );
};

ColumnSelector.defaultProps = {};

export default ColumnSelector;
