import React from "react";
import { StyledButtonIcon } from "./style";
import { Props } from "./types";
import { getIcons } from "../../assets/icons";

const ButtonIcon = (props: Props) => {
  const { icon } = props;

  return (
    <StyledButtonIcon {...props}>
      {icon && <img src={getIcons(icon)} alt={icon} />}
    </StyledButtonIcon>
  );
};

export default ButtonIcon;
