import React from "react";
import "./style.css";
import Page from "../../components/Page";
import { useHistory } from "react-router-dom";
import BottomModal from "../../components/BottomModal";
import Header from "../../components/Header";
import ListItem from "../../components/ListItem";
import Button from "../../components/Button";
import ButtonIcon from "../../components/ButtonIcon";
import { ClipboardImage, MoneyImage, MoneyFlyImage } from "../../assets/emojis";
import { useSelector } from "react-redux";
import { getUser } from "../../store/session";
import {
  getPersonalDetailsPercent,
  getTotalExpenses,
  getTotalIncomes,
} from "../../store/questionnaire";
import MaskHelper from "../../helpers/mask";
import { useTranslation } from "react-i18next";
import LineGradient from "../../components/LineGradient";

const Questionnaires = () => {
  const { t } = useTranslation(["questionnaires"]);
  const history = useHistory();
  const user = useSelector(getUser);
  const totalExpenses = useSelector(getTotalExpenses);
  const totalIncomes = useSelector(getTotalIncomes);
  const percentPersonalDetails = useSelector(getPersonalDetailsPercent);

  return (
    <Page
      backgroundGradient
      headerContent={
        <Header title={user?.name || ""} description={`Financial Discovery`} />
      }
      footerContent={
        totalIncomes &&
        totalExpenses && (
          <Button
            disabled={!totalIncomes || !totalExpenses}
            value={t("viewResult")}
            onClick={() => history.push("profile-result")}
          />
        )
      }
    >
      <BottomModal
        marginTop={90}
        title={t("title")}
        description={t("description")}
      >
        <div className="questionnaires">
          <ListItem
            hasDivider
            iconColor="#f0e6ff"
            icon={ClipboardImage}
            sideContent={
              <ButtonIcon
                icon="arrow"
                rotate={270}
                onClick={() => history.push("personal-details")}
              />
            }
          >
            {t("setPersonalDetails")}
            {percentPersonalDetails > 0 && (
              <div className="personalDetailsValue">
                {percentPersonalDetails > 100 ? 100 : percentPersonalDetails}%
                {t("completed")}
              </div>
            )}
          </ListItem>
          <ListItem
            hasDivider
            iconColor="#e6ffee"
            icon={MoneyImage}
            sideContent={
              <ButtonIcon
                icon="arrow"
                rotate={270}
                onClick={() => history.push("list-incomes")}
              />
            }
          >
            {t("setIncomes")}
            {totalIncomes > 0 && (
              <div className="incomeValue">
                {MaskHelper.currency(totalIncomes || 0, "$")}
              </div>
            )}
          </ListItem>
          <ListItem
            mb={100}
            iconColor="#ffe6e6"
            icon={MoneyFlyImage}
            sideContent={
              <ButtonIcon
                icon="arrow"
                rotate={270}
                onClick={() => history.push("list-categories")}
              />
            }
          >
            {t("setExpenses")}
            {totalExpenses > 0 && (
              <div className="expenseValue">
                {MaskHelper.currency(totalExpenses, "$")}
              </div>
            )}
          </ListItem>
          <LineGradient />
        </div>
      </BottomModal>
    </Page>
  );
};

export default Questionnaires;
