import React, { useState } from "react";
import { DropdownSvg } from "../../assets/icons";
import {
  StyledDropdown,
  StyledSelectedOption,
  StyledOption,
  StyledOptionContainer,
} from "./style";
import { Props } from "./types";

const Dropdown = ({
  width,
  options,
  selectedOption,
  renderOption,
  onSelectOption,
  renderSelectedOption,
}: Props) => {
  const [open, setOpen] = useState(false);

  const onClick = (option: any): void => {
    setOpen(false);
    onSelectOption(option);
  };

  return (
    <StyledDropdown width={width}>
      <StyledSelectedOption onClick={() => setOpen(!open)}>
        {renderSelectedOption(selectedOption, open)}
        <img src={DropdownSvg} alt="handler" />
      </StyledSelectedOption>
      {options && open && (
        <StyledOptionContainer width={width}>
          {options.map((option, index) => (
            <StyledOption key={index} onClick={() => onClick(option)}>
              {renderOption(option)}
            </StyledOption>
          ))}
        </StyledOptionContainer>
      )}
    </StyledDropdown>
  );
};

Dropdown.defaultProps = {
  width: 100,
};

export default Dropdown;
