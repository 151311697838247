import React, { useEffect, useState } from "react";
import "./style.css";
import Page from "../../components/Page";
import BottomModal from "../../components/BottomModal";
import Header from "../../components/Header";
import SavingSuggestion from "../../components/SavingSuggestion";
import ButtonIcon from "../../components/ButtonIcon";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getLanguage, getUser } from "../../store/session";
import {
  getTotalExpenses,
  getTotalIncomes,
  getHasGoals,
} from "../../store/questionnaire";
import MaskHelper from "../../helpers/mask";
import { useTranslation } from "react-i18next";
import LineGradient from "../../components/LineGradient";
import Button from "../../components/Button";
import env from "react-dotenv";

const SAVE_PERCENT = 0.2;

const Result = () => {
  const { t } = useTranslation(["result"]);
  const history = useHistory();
  const user = useSelector(getUser);
  const totalExpenses = useSelector(getTotalExpenses);
  const totalIncomes = useSelector(getTotalIncomes);
  const hasGoals = useSelector(getHasGoals);
  const language = useSelector(getLanguage);
  const [saveValue, setSaveValue] = useState(0);

  useEffect(() => {
    const sugestion = Math.trunc((totalIncomes - totalExpenses) * SAVE_PERCENT);
    setSaveValue(sugestion < 0 ? 0 : sugestion);
  }, [totalIncomes, totalExpenses]);

  const openGoals = () => {
    window.open(
      `${env.URL_GOALS}/${user?.id}/${user?.name}/${saveValue}/${language}/${
        hasGoals ? "update" : "new"
      }`,
      "_self"
    );
  };

  return (
    <Page
      backgroundGradient
      headerContent={
        <>
          <Header
            title={user?.name || ""}
            description={`Financial Discovery`}
            sideContent={
              <ButtonIcon
                icon="pencil"
                onClick={() => history.push("questionnaires")}
              />
            }
          />
          <SavingSuggestion
            mt={16}
            description={t("perMonth")}
            title={t("savingSuggestion")}
            value={MaskHelper.currency(saveValue)}
          />
        </>
      }
      footerContent={
        saveValue > 0 && (
          <Button
            value={t(hasGoals ? "updateGoals" : "newGoals")}
            onClick={openGoals}
          />
        )
      }
    >
      <BottomModal marginTop={180} title={t("title")}>
        <div className="avg">
          <div>{t("avgIncomes")}</div>
          <div>{MaskHelper.currency(totalIncomes, "$")}</div>
        </div>
        <div className="avg">
          <div>{t("avgExpenses")}</div>
          <div>{MaskHelper.currency(totalExpenses, "$")}</div>
        </div>
        <div className="divider"></div>
        <div className="balance">
          <div>{t("balance")}</div>
          <div>{MaskHelper.currency(totalIncomes - totalExpenses, "$")}</div>
        </div>
        <div className="balance-details">
          <div>{t("percentBalance")}</div>
          <div>{MaskHelper.currency(saveValue, "$")}</div>
        </div>
        {saveValue > 0 ? (
          <div className="alert">{t("alert")}</div>
        ) : (
          <div className="alert danger">{t("alert-danger")}</div>
        )}
        <LineGradient />
      </BottomModal>
    </Page>
  );
};

export default Result;
