import styled from "styled-components";
import { Props } from "./types";
import { color } from "styled-system";

export const StyledCountQuestions = styled.div<Props>`
  ${color}
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
`;
