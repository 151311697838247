import React, { useEffect, useState } from "react";
import { StyledContainer, StyledRow, StyledLabel } from "./style";
import { useTranslation } from "react-i18next";
import InputMoney from "../InputMoney";
import { Props } from "./types";

const InputRange = ({ onChange }: Props) => {
  const { t } = useTranslation(["inputRange"]);

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);

  useEffect(() => {
    onChange([start, end]);
  }, [onChange, start, end]);

  const onChangeStart = (value: number): void => {
    setStart(value);
    if (value > end) setEnd(value);
  };

  return (
    <StyledContainer>
      <StyledRow>
        <StyledLabel>{t("from")}</StyledLabel>
        <InputMoney onChangeValue={onChangeStart} value={start} />
      </StyledRow>
      <StyledRow>
        <StyledLabel>{t("to")}</StyledLabel>
        <InputMoney onChangeValue={setEnd} value={end} />
      </StyledRow>
    </StyledContainer>
  );
};

export default InputRange;
